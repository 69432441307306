import React, { useEffect,useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,Collapse, FormGroup, Label, Input, Form } from 'reactstrap';
import { Images } from '../../utils/Images';


const ProductModal = (props) => {
    const [password, setPassword] = useState(props.password)
    const [loading ,setLoading] = useState(false);
    const [loaded ,setLoaded] = useState(false);
    const [error ,setError] = useState(false);

    useEffect(()=>{
      setPassword(props.password)
    },[props])


    return (
      < >
        <Modal isOpen={props.open} toggle={() => props.controller()}>
          <ModalBody>
          <div>
            <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
            <div className="clearfix"></div>
          </div>
          <div className="rp-feed">
            <div className="points-pill mb-3">
              <div >
                <h5>{props.item.title} {props.item.caption ? <small>{props.item.caption}</small> : ""}</h5>
              </div>
            </div>
            <div className='prd-blk'>
              <img src={props.item.image != "" && props.item.image !== null ? `http://newimage.space/ogdru/silas/${props.item.image}` : Images.noImage} />
              <h6>{props.item.description}</h6>
              <h4>${props.item.cost}{props.item.discount ? <span>-{props.item.discount}% discount</span> : ""}</h4>
            </div>

          </div>  
          </ModalBody>
        </Modal>
      </>
    );
}

export default ProductModal;