import React from 'react';

const Buttontb = (props) => {
  let {loader, text, classList} = props;
    return (
      <button type="submit" className={classList} disabled={loader}>{loader ? <i className='fa fa-spinner fa-spin'></i> : text}</button>
    );
      
}

export default Buttontb;