import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import {APIHOST,updateloginStatus,setMerchant,setToken,isLoggedIn,isOutletSet, loadSuccess, loadError, merchantKey,merchantId} from '../../utils/gaia';
import ToolBar from '../Components/toolbarNL'
import classnames from 'classnames';
import Switch from "react-switch";
import Buttontb from '../Components/Button'

import {CardCvcElement,CardExpiryElement,CardNumberElement, useElements, useStripe, useConfirmPayment } from '@stripe/react-stripe-js';


import { Form, FormGroup, Label, Input, ButtonGroup,ButtonDropdown,DropdownToggle, DropdownItem,DropdownMenu } from 'reactstrap';
import { Images } from '../../utils/Images';

const date = new Date();

const loadInit = {
  cardName : "",
  start_d : date.getDate(),
  start_m : date.getMonth() + 1,
  start_y : date.getFullYear(),
}

const period = [ "Day", "Week","Month", "Year"];
 
const Subscription = (props) => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(loadInit)
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  const [canEdit, setCanEdit] = useState(false)

  const [genderOpen, setGenderOpen] = useState(false)
  const [dayOpen, setDayOpen] = useState(false)
  const [yearOpen, setYearOpen] = useState(false)
  const [monthOpen, setMonthOpen] = useState(false)

  const elements = useElements();
  const stripe = useStripe();
  const location = useLocation();
  
  const [load, setLoad] = useState(loadInit);


  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }
  },[])

  const toggleEdit = (c) =>{
    setCanEdit(!canEdit);
  }

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const genYears = () =>{
    let cap = new Date().getFullYear();
    let payload = []
    for(let i = cap; i <= cap+2; i++){
      payload.push(<DropdownItem key={i} onClick={()=>handleDD("y",i)}>{i}</DropdownItem>)
    }
    return payload
  }

  const genMonths = () =>{
    let payload = []
    for(let i = 1; i <= 12; i++){
      payload.push(<DropdownItem key={i} onClick={()=>handleDD("m",i)}>{i}</DropdownItem>)
    }
    return payload
  }

  const genDays = () =>{
    let payload = []
    for(let i = 1; i <= 31; i++){
      payload.push(<DropdownItem key={i} onClick={()=>handleDD("d",i)}>{i}</DropdownItem>)
    }
    return payload
  }



  const toggle = (type) =>{
    if(type == "gender"){
      setGenderOpen(!genderOpen);
    }else if(type == "d"){
      setDayOpen(!dayOpen);
    }else if(type == "m"){
      setMonthOpen(!monthOpen);
    }else if(type == "y"){
      setYearOpen(!yearOpen);
    }
    
  } 

  
  const handleSubmit = async(evt) =>{
    evt.preventDefault();
    if(profile.cardName === ""){
      loadError("Please enter the name on the card.")
      return;
    }

    if (!stripe || !elements) {
      loadError("An error occured on this page, please reload.")
      return;
    }

    let start = `${profile.start_y}-${profile.start_m}-${profile.start_d}`
    
    setLoading(true);
    Axios.post(`${APIHOST}apian/wphook/vulcan`, {
      start : start,
      mct : merchantId,
      sub : location.state.id
    }).then(async function(res){
        let {data} = res;
        let pi = data.payload.clavis;
        let billingDetails = {
          name : profile.cardName,
          phone : data.payload.phone,
        };

        if(data.payload.email != ""){
          billingDetails.email = data.payload.email
        }

        const {error, paymentIntent} = await stripe.confirmCardPayment(pi,{
          payment_method : {
            card : elements.getElement(CardNumberElement),
            billing_details: billingDetails,
            
          }
        })

        if (error) {
          let msg = error.type == "card_error" ? error.message : "An internal error occured. please try again later"
          loadError(msg);
          setLoading(false)
        } else if (paymentIntent) {
          const scribe = await Axios.post(`${APIHOST}apian/wphook/pScribe`,{clavis : paymentIntent.id, start : start, tid : data.payload.txid, mct : merchantId,sub : location.state.id});
          setLoading(false)
          loadSuccess();
          navigate("/app/profile")
        }
      
    }).catch(function(er){
      setLoading(false);
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }

  const handleDD = (type,val) =>{
    let load = profile;
    if(type == "d"){
      load.start_d = val;
    }else if(type == "m"){
      load.start_m = val;
    }else if(type == "y"){
      load.start_y = val;
    } 
    setProfile(load);
  }

    return (
      <>
        <div className="container">
        <ToolBar link="profile" past="/" hasMenu={false} />
        <div id="subscription">
        <button onClick={()=> navigate("/app/profile")} className="btn back-btn" ><i className="fa fa-chevron-left"></i></button>
        <div className="inner">
          {!canEdit ?
          <div className='sub-range'>
            <h4>Membership Payment</h4>
            <p>Select the date you would like your subscription to start:</p>
            <div className='dt-con'>
              <ButtonGroup>
                <ButtonDropdown isOpen={dayOpen} toggle={()=>toggle("d")}>
                  <DropdownToggle className='tb-form-dd' caret>
                    {profile.start_d}
                  </DropdownToggle>
                  <DropdownMenu>
                  {genDays().map((item)=>(item))}
                  </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown isOpen={monthOpen} toggle={()=>toggle("m")}>
                  <DropdownToggle className='tb-form-dd' caret>
                    {profile.start_m}
                  </DropdownToggle>
                  <DropdownMenu>
                  {genMonths().map((item)=>(item))}
                  </DropdownMenu>
                </ButtonDropdown>
                <ButtonDropdown isOpen={yearOpen} toggle={()=>toggle("y")}>
                  <DropdownToggle className='tb-form-dd' caret>
                    {profile.start_y}
                  </DropdownToggle>
                  <DropdownMenu>
                    {genYears().map((item)=>(item))}
                  </DropdownMenu>
                </ButtonDropdown>
            </ButtonGroup>
            </div>
            <p className='nb mt-5 mb-5'>You are about to pay a ${location.state.cost} fee for a <span>{location.state.sub_span} {period[location.state.sub_span_period]}</span> membership.<br />
            After signing up, you will be able to enjoy exclusive rewards and vouchers during your membership validity period.</p>
            <a className='tmpl-main-btn inverse' onClick={toggleEdit}>CARD PAYMENT</a>
          </div>:
          <div className="frm-box">
            <h4>Card Payment</h4>
          <Form autocomplete="off" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="username">Card Name</Label>
              <Input className="text-field" type="text" id="cardname" name="cardName"  placeholder='Enter the name on the card' value={profile.firstname} onChange={event => handleChange(event)} required />
            </FormGroup>
            <FormGroup>
              <Label for="username">Card Number</Label>
              <CardNumberElement className='sub-input' />
            </FormGroup>
            <div className='row'>
              <div className='col-6'>
                <FormGroup>
                  <Label for="username">Card Expiry</Label>
                  <CardExpiryElement className='sub-input' />
                </FormGroup>
              </div>
              <div className='col-6'>
                <FormGroup>
                  <Label for="username">CVC</Label>
                  <CardCvcElement className='sub-input' />
                </FormGroup>
              </div>
            </div>
            <h2>TOTAL COST : ${location.state.cost}</h2>
            <Buttontb loader={loading} text={"PURCHASE"} classList={"tmpl-main-btn filled"} />
          </Form>
        </div>}
        </div>

        </div>
        </div>
      </>
    );
}

export default Subscription;
