import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import ToolBar from '../Components/toolbarNL'


const Privacy = (props) => {
  let navigate = useNavigate();

  useEffect(()=>{
  },[])

  return(
    <div className="container bg">
      <ToolBar title="login" past="/register" hasMenu={false} />
      <div id="terms">
        <div className="inner">
          <div className="top">
            <button onClick={()=> navigate("/register")} className="btn back-btn" ><i className="fa fa-chevron-left"></i></button>
            <div className='p-3'>
              <h4 className='title'>Privacy Policy</h4>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Privacy;
