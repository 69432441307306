import React, { useState,useEffect, useRef } from 'react';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import {getToken, isLoggedIn,destroySession} from './utils/gaia';
import CheckRequests from './utils/interceptor'
import Axios from 'axios';
import IdleTimer from 'react-idle-timer';


//v3.2
import 'bootstrap/dist/css/bootstrap.min.css';
// Import Font Awesome Icons Set
import '@fortawesome/fontawesome-free/css/all.css';

// Import Main styles for this application
import './assets/css/fonts.css'
import './assets/css/styles.css'

//import Main scaffolding
import {Login,Landing,Forgot,NewPwd,Register,Dashboard} from './views/Pages';

import Profile from './views/Scaffolds/Profile'
import Subscription from './views/Scaffolds/Subscription'
import Transactions from './views/Scaffolds/Transactions'
import Terms from './views/Scaffolds/Terms'
import Privacy from './views/Scaffolds/Privacy'
import Menu from './views/Scaffolds/Menu'

const token = getToken()
if (token) {
  Axios.defaults.headers.common['Authorization'] = "Bearer " + token;
}


Axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;  
}, function (error) {       
  if(typeof(error.response) !== "undefined"){
      switch (error.response.status) {
          case 503 :
            
              break
          case 401 :
              destroySession(); 
              window.location.replace("/login")
              //props.history.push('/login') //we will redirect user into 503 page 
              break
          default :
              break
      }
  }
  // Do something with response error
  return Promise.reject(error);
});

const App = () => {

  const [timeout, setTimeout] = useState(1000 * 60 * 20);
  const idleTimerRef = useRef(null);
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(isLoggedIn());

  const _onAction = (e) => {
    console.log("action")
    setIsTimedOut(false)
  }
 
  const _onActive = (e) => {
    console.log("active")
    setIsTimedOut(false)
  }
 
  const _onIdle = (e) => {
    setIsTimedOut(true)
    if (isTimedOut) {
      if(userLoggedIn){
        destroySession();
      }     
    } else {

    }
  }

  return (
    <>
    <IdleTimer
      ref={idleTimerRef}
      element={document}
      onActive={_onActive}
      onIdle={_onIdle}
      onAction={_onAction}
      debounce={250}
      timeout={timeout} />
    <BrowserRouter></BrowserRouter>
    <BrowserRouter>   
      <Routes>
        <Route exact path="/app/*" name="Dashboard" element={<Dashboard />} />
        <Route exact path="/app/profile" name="profile" element={<Profile />} />
        <Route exact path="/app/subscription" name="subscription" element={<Subscription />} />
        <Route exact path="/app/transactions" name="transactions" element={<Transactions />} />
        <Route exact path="/app/menu" name="menu" element={<Menu />} />
        <Route exact path="/app/terms" name="terms" element={<Terms />} />
        <Route exact path="/app/privacy" name="privacy" element={<Privacy />} />
        <Route exact path="/register" name="Register" element={<Register />} />
        <Route exact path="/forgot" name="Forgot Password" element={<Forgot />} />
        <Route exact path="/login" name="Login" element={<Login />} />
        <Route exact path="/new-password" name="New" element={<NewPwd />} />
        <Route exact path="/" name="Landing Page" element={<Landing />} />
      </Routes> 
    </BrowserRouter>
    </>
  );
}

export default App;
