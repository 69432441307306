import React, { Component, useEffect, useState } from 'react';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {APIHOST,isLoggedIn, loadSuccess, loadError, merchantKey,merchantId, showLoading} from '../../utils/gaia';
import ToolBar from '../Components/toolbar'
import classnames from 'classnames';


import { TabPane, TabContent,Nav, NavItem,NavLink, Row, Col } from 'reactstrap';
import { Images } from '../../utils/Images';
import ProductModal from '../Components/ProductModal';
 
const Menu = (props) => {
  const navigate = useNavigate();

  const [tmpPnts, setTmpPnts] = useState([])
  const [cat, setCat] = useState([])
  const [products, setProducts] = useState([])
  const [services, setServices] = useState([])
  const [load, setLoad] = useState([])
  const [loading, setLoading] = useState(false)
  const [pageLoaded, setPageLoaded] = useState(false)
  const [pageError, setPageError] = useState(false)
  //modals
  const [productModal, SetProductModal] = useState(false)

  const [activeTab, setActiveTab] = useState('gra')

  useEffect(()=>{
    if(!isLoggedIn()){
      navigate(`/login`)
    }else{
      loadLoyalty()
    }
  },[])

  const toggle = (tab) =>{
    setActiveTab(tab);
  }

  const loadLoyalty = (evt) =>{
    

    setLoading(true)
    setPageError(false)
    setPageLoaded(false)
  
    Axios.post(`${APIHOST}apian/wphook/products`, {
      mct: merchantId,
    }).then(function(res){
        let {data} = res;
        setLoading(false)
        setCat(data.categoria)
        let p = data.products.data.filter((item)=>{return item.category == 0})
        let s = data.products.data.filter((item)=>{return item.category == 1})
        setProducts(p)
        setServices(s)

        setPageLoaded(true);
        setPageError(false)
    }).catch(function(er){
      setLoading(false)
      setPageLoaded(true);
      setPageError(true)
      if(typeof(er.response) !== "undefined"){
        if(er.response.status == 404 || er.response.status == 500){
          loadError(er.response.data.error)
        }else{
          loadError("A network error occured, please try again later.")
        }
      }else{
        loadError(er.message)
      }
      
    })
  }


  const logImpression = () =>{
    Axios.post(`${APIHOST}apian/wphook/logImpression`, {
      mct: merchantId,
      prd : load.id
    }).then(function(res){
        let {data} = res;
    }).catch(function(er){

    })

  }

  const openProductModal = (prd = null) =>{
    if(productModal == false){
      if(prd){
        setLoad(prd)
      }
      logImpression()
    }
    SetProductModal(!productModal);
  }

    return (
      <>
      <div className="container no-pad">
        <ToolBar link="menu" past="/" hasMenu={false} />
        {pageLoaded ?
        <>
        {!pageError ?
        <>
        <div id="dashboard">
          <div className="inner">
            <div> 
                <Nav tabs className='nav-fill'>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'gra' })}  onClick={() => { toggle('gra'); }}>{cat[0]}</NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className={classnames({ active: activeTab === 'grb' })}  onClick={() => { toggle('grb'); }}>{cat[1]}</NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="gra">
                    <div className="reward-list pl-2 pr-2">
                      <div className="row m-0">
                        {products.length == 0 ?
                        <div className="empty-list">No items available.</div>:
                        <>
                        {products.map(prd=>(
                        <div key={prd.id} className="col-6" onClick={()=>openProductModal(prd)}>
                          <div className="reward-item" for={`rwd_${prd.id}`}>
                          <div className='item-inner'>
                          <a>
                            <img src={prd.image != "" && prd.image !== null ? `http://newimage.space/ogdru/silas/${prd.image}` : Images.noImage} />
                          </a>
                          <div className="reward-content">
                            <div className="reward-content-inner">
                              <h3 className="sky-text">{prd.title}</h3>
                              <p>${prd.cost} {prd.discount ? <span>(-{prd.discount}% discount)</span> : ''}</p>
                            </div>
                          </div>
                          </div>
                        </div>
                    </div>
                    ))}
                    </>}
                  </div>
                </div>
                </TabPane>
                <TabPane tabId="grb">
                <div className="reward-list pl-2 pr-2">
                  <div className="row m-0">
                  {services.length == 0 ?
                    <div className="empty-list">No items available.</div>:
                      <>
                      {services.map(prd=>(
                        <div key={prd.id} className="col-6" onClick={()=>openProductModal(prd)}>
                          <div className="reward-item" for={`rwd_${prd.id}`}>
                          <div className='item-inner'>
                          <a>
                            <img src={prd.image != "" && prd.image !== null ? `http://newimage.space/ogdru/silas/${prd.image}` : Images.noImage} />
                          </a>
                          <div className="reward-content">
                            <div className="reward-content-inner">
                              <h3 className="sky-text">{prd.title}</h3>
                              <p>${prd.cost} {prd.discount ? <span>(-{prd.discount}%)</span> : ''}</p>
                            </div>
                          </div>
                          </div>
                        </div>
                    </div>
                    ))}
                    </>}
                  </div>
                  </div>
                </TabPane>
                </TabContent>
            </div>
          </div>
        </div>
        <div className='footer'>
         
        </div>
        </>:
        <div className='error-screen'>
          <div>
            <p>An error occured, please reload page.</p>
            <a className='reload-btn' onClick={loadLoyalty}>RELOAD</a>
          </div>
        </div>}
        </>:
        <div className='loader'>
          <div>
            <i className='fa fa-spinner fa-spin fa-4x'></i>
            <p>Loading...</p>
          </div>
        </div>}
      </div>
      {productModal ? <ProductModal item={load} open={productModal}  controller={()=>{openProductModal()}}  /> : ""}
    </>
    );
}

export default Menu;
