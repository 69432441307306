import React, { useEffect, useState } from 'react';
import {APIHOST,getToken,destroySession,getMerchantDetail} from '../../utils/gaia';
import Axios from 'axios';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {Images} from '../../utils/Images'

const ToolbarNL = (props) => {
  const navigate = useNavigate();
  const [ddOpen, setDdOpen] = useState(false);

  const handleLogout = () =>{
      var _this = this
      Axios.post(`${APIHOST}logout`,{
        token: getToken()
      }).then(function(res){
        destroySession();
        navigate(`/login`);
      }).catch(function(res){
        destroySession();
        navigate(`/login`);
      });
    }

    const toggle = () =>{
      setDdOpen(!ddOpen)
    }

    return (
      <>
        <nav className="navbar navbar-light tool-bar-nl fixed-top">
          <img src={Images.logo} className="central-logo" />
        </nav>
      </>
    );
      
}

export default ToolbarNL;