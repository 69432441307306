import React, { useEffect, useState,  } from 'react';
import { useNavigate } from 'react-router-dom';
import {isLoggedIn,isOutletSet} from '../../../utils/gaia';

import {Images} from '../../../utils/Images'

const Landing = (props) => {
  let navigate = useNavigate();

  useEffect(()=>{
    if(isLoggedIn()){

      navigate(`/app`)
    }
  },[])

  return(
    <div id="landing">
        <div className="inner">
          <div className="top">
            <img className="landing-logo" src={Images.logo}/>
            <button onClick={()=> {navigate("/login")}} className="tmpl-main-btn filled">LOGIN</button>
            <button onClick={()=> {navigate("/register")}} className="tmpl-main-btn inverse">REGISTER</button>
          </div>
        </div>
      </div>
  )
}

export default Landing;
