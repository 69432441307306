import React, { useEffect,useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {APIHOST, getToken,getMerchantDetail,CDN,RewardCDN} from '../../utils/gaia';
import Axios from 'axios';

import { QRCode } from 'react-qrcode-logo';

import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,Collapse, FormGroup, Label, Input, Form } from 'reactstrap';
import { Images } from '../../utils/Images';


const QrModal = (props) => {
    const [password, setPassword] = useState(props.password)
    const [loading ,setLoading] = useState(false);
    const [loaded ,setLoaded] = useState(false);
    const [error ,setError] = useState(false);

    useEffect(()=>{
      setPassword(props.password)
    },[props])


    return (
      < >
        <Modal isOpen={props.open} toggle={() => props.controller()}>
          <ModalBody>
          <div>
            <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
            <div className="clearfix"></div>
          </div>
          <div className="rp-feed">
            <div className="points-pill mb-3">
              <div >
                <h5>SHOW QR TO OUR STAFF</h5>
              </div>
            </div>
            <div className='qr-blk'>
              <QRCode value={props.qr} fgColor="#440e6b" size="200" logoImage={Images.logo} />
              <Form autoComplete='off'>
                <FormGroup className='manual-input'>
                  <Label for="username">CASHIER TO ASSIST REDEEM</Label>
                  <Input className="text-field" type="password" autoComplete="new-password" id="password" name="password"  placeholder='Enter Sales Person Password' value={props.password} onChange={event => props.handleChange(event.target.value)} />
                </FormGroup>
              </Form>
              {props.items.usable_rwds.length > 0 ? 
              <><div className='rdm-hdr'>Rewards</div>
              <ol className='rdm-list'>{props.items.usable_rwds.map(rwd => (
                <li>{rwd}</li>
              ))}</ol></>: ""}
              {props.items.usable_pkgs.length > 0 ? 
              <><div className='rdm-hdr'>Packages</div>
              <ol className='rdm-list'>{props.items.usable_pkgs.map(rwd => (
                <li>{rwd}</li>
              ))}</ol></>: ""}
              {props.cash !=  "" ? 
              <><div className='rdm-hdr'>Wallet</div>
              <ol className='rdm-list'><li>${props.cash}</li></ol></>: ""}
            </div>
            <button onClick={props.claim} className="tmpl-main-btn filled">Done</button>
          </div>  
          </ModalBody>
        </Modal>
      </>
    );
}

export default QrModal;