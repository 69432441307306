import React, { useEffect,useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import {APIHOST, getToken,getMerchantDetail,CDN,RewardCDN} from '../../utils/gaia';
import Axios from 'axios';

import { Button,ButtonGroup, Modal, ModalHeader, ModalBody, ModalFooter,Collapse } from 'reactstrap';
import { Images } from '../../utils/Images';


const ClaimModal = (props) => {

    const [loading ,setLoading] = useState(false);
    const [loaded ,setLoaded] = useState(false);
    const [points ,setPoints] = useState(props.points);
    const [point_sys ,setPointSys] = useState(props.point_sys);
    const [pnts ,setPnts] = useState(props.pnts);
    const [error ,setError] = useState(false);

    useEffect(()=>{
 
    },[props])


    return (
      < >
        <Modal isOpen={props.open} toggle={() => props.controller()}>
          <ModalBody>
          <div>
            <button className="close-modal" onClick={() => props.controller()}><i className="fa fa-times"></i></button>
            <div className="clearfix"></div>
          </div>
          <div className="rp-feed">
            <div className="points-pill mb-3">
              <div >
                <h5>Redeem Items</h5>
                <p>You are about to redeem:</p>
              </div>
            </div>
            {props.items.usable_rwds.length > 0 ? 
              <><div className='rdm-hdr'>Rewards</div>
              <ol className='rdm-list'>{props.items.usable_rwds.map(rwd => (
                <li>{rwd}</li>
              ))}</ol></>
            : ""}
            {props.items.usable_pkgs.length > 0 ? 
              <><div className='rdm-hdr'>Packages</div>
              <ol className='rdm-list'>{props.items.usable_pkgs.map(rwd => (
                <li>{rwd}</li>
              ))}</ol></>
            : ""}

            {props.cash !=  "" ? 
              <><div className='rdm-hdr'>Wallet</div>
              <ol className='rdm-list'><li>${props.cash}</li></ol></>
            : ""}
            
            <button onClick={props.claim} className="tmpl-main-btn filled">Redeem Items</button>
          </div>  
          </ModalBody>
        </Modal>
      </>
    );
}

export default ClaimModal;