import React, { useEffect, useState } from 'react';
import {APIHOST,getToken,destroySession,getMerchantDetail} from '../../utils/gaia';
import Axios from 'axios';
import {ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import {Images} from '../../utils/Images'

const Toolbar = (props) => {
  const navigate = useNavigate();
  const [ddOpen, setDdOpen] = useState(false);

  const handleLogout = () =>{
      var _this = this
      Axios.post(`${APIHOST}logout`,{
        token: getToken()
      }).then(function(res){
        destroySession();
        navigate(`/login`);
      }).catch(function(res){
        destroySession();
        navigate(`/login`);
      });
    }

    const toggle = () =>{
      setDdOpen(!ddOpen)
    }

    return (
      <>
        <div className=" tool-bar">
          <img src={Images.logo} className="central-logo" />
          <a onClick={handleLogout} className='logout' ><i className="fa fa-power-off"></i></a>
          <img src={Images.banner} className="nav-banner" />
          <div className='nav-links'>
            <a href="/app" className={`navs tmpl-main-btn ${props.link == "home" ? 'inverse' : 'filled'}`}><i className='fa fa-home'></i></a>
            <a href="/app/transactions" className={`navs tmpl-main-btn ${props.link == "transactions" ? 'inverse' : 'filled'}`}>Transactions</a>
            <a href="/app/menu" className={`navs tmpl-main-btn ${props.link == "menu" ? 'inverse' : 'filled'}`}>Menu</a>
            <a href="/app/profile" className={`navs tmpl-main-btn ${props.link == "profile" ? 'inverse' : 'filled'}`}>Profile</a>
          </div>
        </div>
        
      </>
    );
      
}

export default Toolbar;